<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol md="7">
                            <h4>Fraud Sentinel Instructions</h4>
                        </CCol>
                        <CCol md="5">
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <iframe id="fred" style="border:1px solid #666CCC"
                        title="Fraud Sentinel Instructions" :src="pdf_file"
                        frameborder="1" scrolling="auto"
                        width="100%" height="600px" >
                    </iframe>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>

export default {
    name: "fraudsentinelinstruction",
    data() {
        return {
            pdf_file:'Antifraud-Instructions.pdf'
        };
    },
    filters: {
    },
    created() {
    },
    methods: {
    }
};
</script>
